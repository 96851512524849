/* Common imports */

import { styled } from "@mui/system";
import React from "react";
/* mui imports */
import { Checkbox, FormControlLabel, FormGroup, Stack, TextField, Typography, useTheme } from "@mui/material";
import StateInterface from "../../../../redux-magic/state-interface";
import { SearchFilterId } from "../../../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk, updateSearchFilterSelectedOptionsThunk } from "../../../../redux-magic/thunks";

/* redux imports */

/* Stylings */

const Text = styled(TextField)(({ theme }) => ({
	"& .MuiOutlinedInput-root": {
		borderRadius: "0rem",
		fontSize: "0.813rem",
		fontWeight: "400",
		lineHeight: "1.125rem",
		width: "6.813rem",
		height: "2.125rem",
	},
}));

const SubText = styled(Typography)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	fontSize: "0.813rem",
	fontWeight: "400",
	lineHeight: "1.125rem",
	padding: "3px 6px",
}));

const BedroomComponent = ({
	search_id,
	dispatch,
	search_filter_state,
}: {
	search_id: SearchFilterId;
	dispatch: Function;
	search_filter_state: StateInterface["search_filters_state"]["bedrooms_variant_2"];
}) => {
	const theme = useTheme();
	const [selectedBHK, setSelectedBHK] = React.useState<Array<any>>(
		search_filter_state.selected_options.length > 0 ? [...search_filter_state.selected_options] : [],
	);

	React.useEffect(() => {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: selectedBHK,
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedBHK]);

	const handleBedroomClick = (id: string, title: string, value: string) => {
		setSelectedBHK((prevState) => {
			const isAlreadySelected = prevState.some((option) => option.id === id);

			if (isAlreadySelected) {
				return prevState.filter((option) => option.id !== id);
			} else {
				return [...prevState, { id, title, value }];
			}
		});

		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: search_id,
				isApplied: true,
			}),
		);
	};

	return (
		<Stack
			direction="row"
			spacing={3}
			// marginBottom={2}
		>
			<FormGroup
				sx={{
					display: "flex",
					flexDirection: "row",
					[theme.breakpoints.down("xsPlus")]: { paddingLeft: "1rem" },
				}}
			>
				{search_filter_state.active_options.map((item: any) => {
					const isSelected = selectedBHK.some((option) => option.id === item.id);
					return (
						<FormControlLabel
							id={"home_desktop_filters_bedrooms_" + item.id}
							key={item.id}
							control={<Checkbox checked={isSelected} />}
							label={item.title}
							onChange={() => {
								handleBedroomClick(item.id, item.title, item.value);
							}}
						/>
					);
				})}
			</FormGroup>
		</Stack>
	);
};
export default BedroomComponent;
